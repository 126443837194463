import React from 'react'
import { Link } from 'gatsby'
import { Text } from 'theme-ui'

import theme from './theme'

const TagLink = (props) => (
    <Text
      {...props}
      color='white'
      px={[0, null, 2]}
      css={{
        display: 'inline-block',
        backgroundImage: "linear-gradient(transparent 25px, " + theme.colors.red + " 10px)"
      }}>
    </Text>
)

export default TagLink
