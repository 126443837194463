import React from 'react'
import { Link } from 'gatsby'

import {
  Grid,
  Box,
  Heading,
  Text,
  Image
} from 'theme-ui'

import palmLogo from '../images/palm_logo.png'

const Nav = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0
    }}>
    <Grid
      columns={2}
      sx={{
        justifyContent: 'space-between'
      }}>
      <Box>
        <Link
          to='/'>
          <Image width={100} src={palmLogo} />
        </Link>
      </Box>
      <Box
        sx={{
          ml: 'auto'
        }}>
        <Link
          to='/'>
          <Image width={100} src={palmLogo} />
        </Link>
      </Box>
    </Grid>
  </Box>
)

export default Nav
