import React from 'react'
import { Link } from 'gatsby'

import {
  Box,
  Heading,
  Text,
  Grid
} from 'theme-ui'

import Layout from '../components/layout'
import Container from '../components/container'
import Header from '../components/header'
import TagLink from '../components/tagLink'
import Nav from     '../components/nav'
import BottomNav from     '../components/bottomNav'

import theme from '../components/theme'

const IndexPage = () => (
  <Layout>
    <Nav />
    <Header roleTitle='creatives' />

    <Grid
      columns={[1, null, '1fr 10fr 1fr']}
      sx={{
        py: 3,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <Box sx={{
        display: ['none', null, 'block']
        }}>
        <Link
          css={{textDecoration: 'none'}}
          href='mailto:hello@thrdhnd.com'>
          <Heading
            sx={{
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
              color: 'red'
            }}>
            Click here to contact us
          </Heading>
        </Link>
      </Box>
      <Box sx={{
        px: [2, null, 0],
        pb: [4, null, 0]
        }}>
        <Text sx={{textAlign: ['left', null, 'center'], fontSize: [4, 5], lineHeight: 'body'}} color='white'>
          Hi. We’re Third Hand – a genus of developers, marketers, photographers, and creatives based in Seattle, WA. We're passionate about <TagLink page='web'>web development</TagLink>, <TagLink page='marketing'>digital marketing</TagLink>, <TagLink page='photography'>photography</TagLink>, and <TagLink page='social'>social media</TagLink>.
        </Text>
      </Box>
      <Box
        sx={{
          ml: 'auto',
          right: 0,
          display: ['none', null, 'block']
        }}>
        <Link
          css={{textDecoration: 'none'}}
          href='mailto:hello@thrdhnd.com'>
          <Heading
            sx={{
              writingMode: 'vertical-rl',
              color: 'red'
            }}>
            Click here to contact us
          </Heading>
        </Link>
      </Box>
    </Grid>

    <Grid
      sx={{
        justifyContent: 'center'
      }}>
      <Box pb={4}>
        <Heading fontWeight='bold'
          sx={{
            fontSize: [4, 6],
            color: 'offwhite'
          }}
          pb={1}>
          LET'S WORK TOGETHER
        </Heading>
        <Text
          sx={{
            fontSize: [4, 6],
            fontWeight: 8,
            textAlign: 'center',
            color: 'offwhite',
            pb: 4,
            zIndex: 9,
            position: 'relative'
          }}><Link css={{color: theme.colors.offwhite}} href="mailto:hello@thrdhnd.com?subject=Let's work together">hello@thrdhnd.com</Link></Text>
      </Box>
      {/*
      <Box width={1} py={1}>
        <Text fontSize={5} lineHeight={1.25}>
          Third Hand was founded by Cale Sennett and Kate Van Petten. Cale starting coding from a young age, and has made hundreds of websites, apps, and tools for developers. He’s worked for Shopify, Mark Cuban, and many other startups and local businesses. Check out his github or his latest project. Kate has worked in Marketing for companies big and small- viral video startups, cafes, restaurants, theatres, and a radio station. Together, they run Third Hand in Seattle, WA. Their goal is to make websites, online stores, marketing campaigns, and social media feeds better for everyone.
        </Text>
      </Box>
      */}
    </Grid>
    <BottomNav />
  </Layout>
)

export default IndexPage
